<template>
  <div class="list-con-m">
    <leftM ref="leftMRef" @cateData="cateData"></leftM>
    <div class="right-m-con single-page">
      <div class="article-details">
        <Loading :isShow="isShow"></Loading>
        <div v-if="articleDetails" class="article-con-title">
          <div class="a-title">{{articleDetails.title}}</div>
          <div class="send-time-source">
            <div class="item-val">发布时间：{{articleDetails.create_time}}</div>
            <div class="item-val">来源：{{articleDetails.source || '未知'}}</div>
          </div>
          <div class="con-details" v-html="articleDetails.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import leftM from "@/components/leftM";
export default {
  name: "SinglePage",
  components:{
    leftM
  },
  data(){
    return {
      aId:null,
      cateData: {
        _child: null,
        indexId: 0
      },
      domain:this.ApiUrl,
      isShow:false,
      articleDetails:null
    }
  },
  watch:{
    cateData(e){
      this.$refs.leftMRef.initData(e);
    },
    "$route.query.aId"(aId){
      this.aId = aId;
      this.init(1);
    }
  },
  created() {
    this.aId = this.$route.query.aId;
  },
  mounted() {
    this.init(1);
    this.$refs.leftMRef.initData(this.cateData);
  },
  methods:{
    init(type){
      this.isShow = true;
      this.requestApi('getArticleDetails', {aId:this.aId}).then((res) => {
        if(res.status==1){
          if(type) this.cateData = res.cateData;
          this.articleDetails = res.articleDetails;
        }
        this.$nextTick(()=>{
          this.isShow = false;
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import "~/src/assets/css/single_page";
</style>