import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import List from '../views/List.vue'
import GraphicList from '../views/GraphicList.vue'
import SinglePage from '../views/SinglePage.vue'
import ArticleDetails from '../views/ArticleDetails.vue'
import error from '../views/error.vue'
import MessageBoard from '../views/MessageBoard.vue'
import DonationList from '../views/DonationList.vue'
import ExpendList from '../views/ExpendList.vue'
import Search from '../views/Search.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/list',
    name: 'List',
    component: List
  },
  {
    path: '/graphic_list',
    name: 'GraphicList',
    component: GraphicList
  },
  {
    path: '/single_page',
    name: 'SinglePage',
    component: SinglePage
  },
  {
    path: '/article_details',
    name: 'ArticleDetails',
    component: ArticleDetails
  },
  {
    path: '/message_board',
    name: 'MessageBoard',
    component: MessageBoard
  },
  {
    path: '/donation_list',
    name: 'DonationList',
    component: DonationList
  },
  {
    path: '/expend_list',
    name: 'ExpendList',
    component: ExpendList
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
  {
    path:'*',
    component:error,
    name:'error',
    meta:{
      title:'页面没找到'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
