<template>
  <div class="loading-bg" v-if="isShow">
    <b-spinner variant="primary" label=""></b-spinner>
    <span class="label-m">{{label}}</span>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props:{
    isShow:{
      type:Boolean,
      default:false
    },
    label:{
      type:String,
      default:''
    }
  }
}
</script>

<style lang="scss">
  .loading-bg{
    display: flex;width: 100%;height: 100%;position: absolute;top: 0;left: 0;background: rgba(255,255,255,.6);justify-content: center;align-items: center;
    .label-m{
      padding-left: 10px;font-size: 14px;color: #0075e9;
    }
  }
</style>