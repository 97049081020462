<template>
  <div class="empty-m" v-if="isShow">
    <div class="empty-msg">暂无数据</div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props:{
    isShow:{
      type:Boolean,
      default:false
    }
  }
}
</script>

<style lang="scss">
  .empty-m{
    display: flex;position: absolute; width: 100%;height: 100%;justify-content: center;align-items: center;
    .empty-msg{
      font-size: 14px;color: #999999;
    }
  }
</style>