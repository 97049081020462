<template>
  <div class="footer-m">
    <div class="footer-con">
      <div class="con-list">
        <div class="footer-m-msg" v-html="$store.state.sysConf.sys_footer_con"></div>
        <div class="technical-support">技术支持：
          <b-link class="link-sup" target="_blank" href="http://qfyu.net/">深圳庆丰裕信息科技有限公司</b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footerM"

}
</script>
<style lang="scss">
  .footer-m{
    width: 100%;background: #70777d;
    .footer-con{
      display: flex;width: 1200px;margin: 0 auto;height: 100%;justify-content: center;padding:20px 0;
      .con-list{
        .footer-m-msg{
          font-size: 14px;color: #ffffff;line-height: 30px;text-align: center;
          p{
            font-size: 14px;color: #ffffff;line-height: 30px;text-align: center;padding:0;margin: 0;
          }
        }
        .technical-support{
          font-size: 14px;color: #ffffff;line-height: 30px;text-align: center;
          .link-sup{
            font-size: 14px;color: #ffffff;text-decoration: none;text-align: center;
          }
        }
      }
    }
  }
</style>