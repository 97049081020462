<template>
  <div class="">
    <div class="ser-article-list">
      <Loading :isShow="isShow"></Loading>
      <div v-for="(item,index) in articleData" :key="index">
        <b-link class="item-list" :to="{path:'/article_details',query:{aId:item.id}}">
          <div class="img-m">
            <b-img-lazy class="pro-img" :src="domain + item.img" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" blank-color="#f3f3f3"></b-img-lazy>
          </div>
          <div class="a-msg">
            <div class="pro-title">{{item.title}}</div>
            <div class="source">来源：{{item.source || '未知'}}</div>
            <div class="create-time">发布时间：{{item.create_time}}</div>
            <div class="description">{{item.description}}</div>
          </div>
        </b-link>
        <div class="mid-line"></div>
      </div>
      <Empty :isShow="!rows && !isShow"></Empty>
    </div>
    <b-pagination v-if="rows" class="page-cust" @change="changePage" v-model="currentPage" align="center" :per-page="perPage" hide-goto-end-buttons :total-rows="rows" :limit="10" prev-text="上一页"  next-text="下一页"></b-pagination>
  </div>
</template>

<script>
import Empty from '@/components/Empty'
export default {
  name: "Search",
  components:{
    Empty
  },
  data(){
    return {
      keywords:'',
      domain:this.ApiUrl,
      articleData:null,
      currentPage:1,
      perPage:10,
      rows:0,
      isShow:false
    }
  },
  watch:{
    "$route.query.keywords"(keywords){
      this.keywords = keywords;
      this.init();
    }
  },
  created() {
    this.keywords = this.$route.query.keywords;
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      this.isShow = true;
      this.requestApi('getSearchArticleList', {keywords:this.keywords,page:this.currentPage}).then((res) => {
        if(res.status==1){
          this.articleData = res.articleData.data;
          this.rows = res.articleData.total;
          this.perPage = res.articleData.per_page;
        }
        this.$nextTick(()=>{
          this.isShow = false;
        })
      })
    },
    changePage(e){
      this.currentPage = e;
      this.init(0);
    }
  }
}
</script>

<style lang="scss">
@import "~/src/assets/css/search";
</style>