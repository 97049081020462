import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    linkType:{},
    singleUrl:{},
    menuTree:[],
    recProject:[],
    donationCate:[],
    sysConf: {
      sys_account: "{}", sys_account_code: '', sys_footer_con: ''
    }
  },
  mutations: {
    setLinkType(state,val){
      state.linkType = val
    },
    setSingleUrl(state,val){
      state.singleUrl = val
    },
    setMenuTree(state,val){
      state.menuTree = val
    },
    setRecProject(state,val){
      state.recProject = val
    },
    setDonationCate(state,val){
      state.donationCate = val
    },
    setSysConf(state,val){
      state.sysConf = val
    }
  },
  actions: {
    editLT({commit},val){
      commit('setLinkType',val)
    },
    editSU({commit},val){
      commit('setSingleUrl',val)
    },
    editMT({commit},val){
      commit('setMenuTree',val)
    },
    editSC({commit},val){
      commit('setSysConf',val)
    },
    editRP({commit},val){
      commit('setRecProject',val)
    }
  },
  modules: {
  }
})
