<template>
  <div class="list-con-m">
    <leftM ref="leftMRef" @cateData="cateData"></leftM>
    <div class="right-m-con graphic-list-m">
      <div class="article-list">
        <Loading :isShow="isShow"></Loading>
        <div class="article-img-list">
          <div class="item-m-con" v-for="(item,index) in articleData" :key="index">
            <b-link class="item-list" :to="{path:'/article_details',query:{aId:item.id}}">
              <div class="img-m">
                <b-img-lazy class="pro-img" :src="domain + item.img" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" blank-color="#f3f3f3"></b-img-lazy>
              </div>
              <div class="pro-title">{{item.title}}</div>
            </b-link>
          </div>
          <Empty :isShow="!rows && !isShow"></Empty>
        </div>
      </div>
      <b-pagination v-if="rows" class="page-cust" @change="changePage" v-model="currentPage" align="center" :per-page="perPage" hide-goto-end-buttons :total-rows="rows" :limit="10" prev-text="上一页"  next-text="下一页"></b-pagination>
    </div>
  </div>
</template>

<script>
import leftM from "@/components/leftM";
import Empty from '@/components/Empty'
export default {
  name: "GraphicList",
  components:{
    leftM,Empty
  },
  data(){
    return {
      typeId:null,
      cateData: {
        _child: null,
        indexId: 0
      },
      domain:this.ApiUrl,
      articleData:null,
      currentPage:1,
      perPage:8,
      rows:0,
      isShow:false
    }
  },
  watch:{
    cateData(e){
      this.$refs.leftMRef.initData(e);
    },
    "$route.query.typeId"(typeId){
      this.typeId = typeId;
      this.init(1);
    }
  },
  created() {
    this.typeId = this.$route.query.typeId;

  },
  mounted() {
    this.init(1);
    this.$refs.leftMRef.initData(this.cateData);
  },
  methods:{
    init(type){
      this.isShow = true;
      this.requestApi('getArticleList', {typeId:this.typeId,page:this.currentPage,list_rows:16}).then((res) => {
        if(res.status==1){
          if(type) this.cateData = res.cateData;
          this.articleData = res.articleData.data;
          this.rows = res.articleData.total;
          this.perPage = res.articleData.per_page;
        }
        this.$nextTick(()=>{
          this.isShow = false;
        })
      })
    },
    changePage(e){
      this.currentPage = e;
      this.init(0);
    }
  }
}
</script>

<style lang="scss">
@import "~/src/assets/css/graphic_list";
</style>