<template>
  <div class="list-con-m">
    <leftM ref="leftMRef" @cateData="cateData"></leftM>
    <div class="right-m-con donation-list">
      <div class="d-l-item">
        <Loading :isShow="isShow"></Loading>
        <div class="ser-m">
          <div class="input-m">
            <div class="label-t">捐赠时间：</div>
            <div class="input-val">
              <b-datepicker class="input-date"  placeholder="起始时间" v-model="serData.start_date" locale="zh" reset-button label-reset-button="清除" label-help="" label-no-date-selected="选择起始日期" ></b-datepicker>
              <div class="mid">至</div>
              <b-datepicker class="input-date" placeholder="结束时间" v-model="serData.end_date" locale="zh" reset-button label-reset-button="清除" label-help="" label-no-date-selected="选择结束日期"></b-datepicker>
            </div>
          </div>
          <div class="input-m">
            <div class="label-t">捐赠金额：</div>
            <div class="input-val">
              <b-input trim type="number" v-model="serData.start_p" number class="input-c" placeholder=""></b-input>
              <div class="mid">至</div>
              <b-input trim type="number" v-model="serData.end_p" number class="input-c" placeholder=""></b-input>
            </div>
          </div>
          <div class="input-m">
            <div class="label-t">捐赠人类型：</div>
            <div class="input-val">
              <b-link class="item-btn" v-for="(item,index) in u_type_arr" :key="index" :class="{'cur':serData.u_type==item.val}"  @click="serData.u_type = item.val">{{ item.label }}</b-link>
            </div>
          </div>
          <div class="input-m">
            <div class="label-t">捐赠人：</div>
            <div class="input-val">
              <b-input trim class="input-c" v-model="serData.name" placeholder=""></b-input>
              <b-link class="f-btn ser-btn" @click="changePage(1)">筛选</b-link>
              <b-link class="f-btn reset-btn" @click="resetFun">重置</b-link>
            </div>
          </div>
        </div>
        <div class="log-list">
          <b-table bordered  caption-top fixed :fields="fields" :items="listData" :show-empty="!isShow">
            <template v-slot:empty>
              <div class="table-list-empty">未查询到相关数据</div>
            </template>
          </b-table>
        </div>
        <b-pagination v-if="rows" class="page-cust" @change="changePage" v-model="currentPage" align="center" :per-page="perPage" hide-goto-end-buttons :total-rows="rows" :limit="10" prev-text="上一页"  next-text="下一页"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import leftM from "@/components/leftM";
const serData = {start_date:null,end_date:null,name:'',start_p:null,end_p:null,u_type:0};
export default {
  name: "DonationList",
  components:{
    leftM
  },
  data(){
    return {
      typeId:null,
      cateData: {
        _child: null,
        indexId: 0
      },
      u_type_arr:[
        {val:0, label:'全部'},
        {val:1, label:'个人'},
        {val:2, label:'企业'},
        {val:3, label:'团体'},
      ],
      domain:this.ApiUrl,
      isShow:false,
      listData:null,
      fields:[
        {key:'donation_date',label:'时间',thClass:'table-th-c',thStyle:'width:10%;',tdClass:'table-td-c'},
        {key:'a_title',label:'项目名称',thClass:'table-th-c',thStyle:'width:25%;',tdClass:'table-td-c',
          formatter: function (value) {
            if (!value) return '非定向';
            else return value;
          }
        },
        {key:'price',label:'金额',thClass:'table-th-c',thStyle:'width:13%;',tdClass:'table-td-c td-color-red'},
        {key:'u_type',label:'用户类型',thClass:'table-th-c',thStyle:'width:12%;',tdClass:'table-td-c',
          formatter: function (value) {
            if (!value) return '--';
            else return value==1?'个人':(value==2?'企业':'团体');
          }
        },
        {key:'name',label:'用户名称',thClass:'table-th-c',thStyle:'width:13%;',tdClass:'table-td-c'},
        {key:'content',label:'爱心祝福',thClass:'table-th-c',thStyle:'width:27%;',tdClass:'table-td-c',
          formatter: function (value) {
            if (!value) return '--';
            else return value;
          }
        }
      ],
      currentPage:1,
      perPage:8,
      rows:0,
      serData: JSON.parse(JSON.stringify(serData))
    }
  },
  watch:{
    cateData(e){
      this.$refs.leftMRef.initData(e);
    }
  },
  mounted() {
    this.init(1);
    this.$refs.leftMRef.initData(this.cateData);
  },
  methods:{
    init(type){
      this.isShow = true;
      this.requestApi('getDonationList', {page:this.currentPage,serData:this.serData}).then((res) => {
        if(res.status==1){
          if(type) this.cateData = res.cateData;
          this.listData = res.list.data;
          this.rows = res.list.total;
          this.perPage = res.list.per_page;
        }
        this.$nextTick(()=>{
          this.isShow = false;
        })
      })
    },
    changePage(e) {
      this.currentPage = e;
      this.init(0);
    },
    resetFun() {
      this.serData = JSON.parse(JSON.stringify(serData))
    }
  }
}
</script>

<style lang="scss">
@import "~/src/assets/css/donation_list";
</style>