<template>
  <div class="home-m">
    <div style="position: relative;">
      <Loading :isShow="fShow"></Loading>
      <b-link class="link-carousel" target="_blank" :href="bannerList.length > 0?bannerList[bannerSlide].url:'#'">
        <b-carousel class="carousel-fade-banner" v-model="bannerSlide" :interval="4000" fade indicators background="#f3f3f3" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
          <b-carousel-slide v-for="(item,index) in bannerList" :key="index" :img-src="domain + item.value" :data-url="item.url"></b-carousel-slide>
        </b-carousel>
      </b-link>
      <div class="part-main">
        <div class="item-nav-title">
          <div class="left">
            <div class="label">News</div>
            <div class="name-s">新闻动态</div>
          </div>
        </div>
        <div class="item-list-article">
          <div class="left">
            <div class="top-nav">
              <div class="cate-menu">
                <div v-for="(item,index) in cateArticleData9.cate" :class="'cate-hot-l'+(cateArticleData9.index==item.id?' cur':'')" :key="index">
                  <b-link @click.stop="toUrl(item.link_type,item.id)" class="label-url" @mouseover="cateArticleData9.index = item.id">
                    <span class="label-m">{{item.name}}</span>
                    <div v-if="cateArticleData9.index==item.id" class="cur-bottom"></div>
                  </b-link >
                  <span v-if="(index+1) != cateArticleData9.cate.length" class="division">/</span>
                </div>
              </div>
              <b-link to="/list/9" class="more-data"></b-link >
            </div>
            <div class="article-list">
              <div class="a-l-m">
                <b-link class="item" v-for="(item,index) in cateArticleData9.cate_article[cateArticleData9.index]" :to="{path:'/article_details',query:{aId:item.id}}" :key="index">
                  <div class="point"></div>
                  <div class="title">{{item.title}}</div>
                  <div class="c-time">{{item.create_time.substring(0,10)}}</div>
                </b-link>
              </div>
            </div>
          </div>
          <div class="right">
            <b-link class="link-carousel" :to="{path:'/article_details',query:{aId:cateArticleData9.article.length > 0?cateArticleData9.article[slide].id:0}}">
              <b-carousel class="carousel-fade-article" v-model="slide" :interval="4000" fade controls label-next="" label-prev="" background="#000103" width="500" heigth="336">
                <b-carousel-slide class="article-img-m" v-for="(item,index) in cateArticleData9.article" :key="index" :img-src="domain + item.img">
                  <div class="title">{{ item.title }}</div>
                </b-carousel-slide>
              </b-carousel>
            </b-link>
          </div>
        </div>
      </div>
    </div>
    <b-link class="link-carousel" target="_blank" :href="adsense_data.length > 0?adsense_data[adsenseSlide].url:'#'">
      <b-carousel class="carousel-fade-adsense" v-model="adsenseSlide" :interval="4000" fade background="#ababab" style="text-shadow: 0px 0px 2px #000">
        <b-carousel-slide v-for="(item,index) in adsense_data" :key="index" :img-src="domain + item.value"></b-carousel-slide>
      </b-carousel>
    </b-link>
    <div class="part-main">
      <div class="item-nav-title heigth140">
        <div class="left">
          <div class="label">Styles</div>
          <div class="name-s">会员风采</div>
        </div>
        <div class="right">
          <b-link class="cate-hot-l" v-for="(item,index) in cateArticleData17.cate" :key="index" @click="toUrl(item.link_type,item.id)">
            <div class="img-bg-c">
              <b-img-lazy class="img" width="98" height="86" :src="domain + item.img" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" blank-color="#f3f3f3"></b-img-lazy>
            </div>
            <div class="label-m">{{item.name}}</div>
          </b-link>
        </div>
      </div>
      <div class="item-list">
        <b-link class="article-hot-l" v-for="(item,index) in cateArticleData17.article" :key="index" :to="{path:'/article_details',query:{aId:item.id}}">
          <div class="img-m">
            <b-img-lazy class="img" :src="domain + item.img" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" blank-color="#f3f3f3"></b-img-lazy>
          </div>
          <div class="label-m">{{item.title}}</div>
        </b-link>
      </div>
    </div>
    <div class="bg-p-con">
      <div class="part-main">
        <div class="item-list-article">
          <div class="left">
            <div class="top-btn-nav">
              <b-link class="label-url" v-for="(item,index) in homeMenuArticleData.cate" :class="'cate-hot-l'+(homeMenuArticleData.index==item.id?' cur':'')" :to="{path:'/article_details',query:{aId:item.id}}" :key="index"  @mouseover="homeMenuArticleData.index = item.id">
                {{item.name}}
              </b-link >
            </div>
            <div class="article-list">
              <div class="a-l-m">
                <b-link class="item" v-for="(item,index) in homeMenuArticleData.article[homeMenuArticleData.index]" :to="{path:'/article_details',query:{aId:item.id}}" :key="index">
                  <div class="point"></div>
                  <div class="title">{{item.title}}</div>
                  <div class="c-time">{{item.create_time.substring(0,10)}}</div>
                </b-link>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="top-btn-nav">
              <b-link herf="javascript:;" class="label-url cur">
                活动公告
              </b-link >
            </div>
            <div class="article-list">
              <div class="a-l-m">
                <b-link class="item" v-for="(item,index) in homeMenuArticleData.charityArticle" :key="index">
                  <div class="point"></div>
                  <div class="title">{{item.title}}</div>
                  <div class="c-time">{{item.create_time.substring(0,10)}}</div>
                </b-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="part-main part-link-m">
      <div class="item-nav-title">
        <div class="left">
          <div class="label">Links</div>
          <div class="name-s">友情链接</div>
        </div>
        <div class="link-menu">
          <div class="link-m-item" v-for="(item,index) in linkData.linkTree" :key="index">
            <b-link :class="'b-link' +(linkData.index==index?' cur':'')" herf="javascript:;" @mouseover="linkData.index = index">
              {{item.name}}
            </b-link >
            <span class="division" v-if="(index+1) != linkData.linkTree.length">|</span>
          </div>
        </div>
      </div>
      <div class="item-list-link">
        <scroll ref="seamlessScroll" v-if="updateScrollSataus" :data="linkData.linkTree[linkData.index]._child" :class-option="classOption" class="table-content">
          <div class="link-s-m" v-for="(item,index) in linkData.linkTree[linkData.index]._child" :key="index">
            <b-link class="link-child-item" :alt="item.name" :title="item.name" target="_blank" :href="item.link_url">
              <b-img class="link-img" :src="domain + item.img" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" fluid alt="Responsive image" blank-color="#f3f3f3"></b-img>
            </b-link >
          </div>
        </scroll>
      </div>
    </div>
    <div class="h-footer-m">
      <div class="h-footer-con">
        <div class="con-m-item">
          <div class="item-list" v-for="(item,index) in menuTree" :key="index">
            <b-link class="p-cate" @click="toUrl(item.link_type,item.id)" :title="item.name" :alt="item.name">{{item.name}}</b-link>
            <b-link class="cate-child" v-for="(val,key) in item._child" :key="key" @click="toUrl(val.link_type,val.id)" :title="val.name" :alt="val.name">{{val.name}}</b-link>
          </div>
        </div>
        <div class="img-right">
          <div class="p-cate">扫一扫关注福田区企业家协会</div>
          <b-img-lazy class="code-img" :src="domain + $store.state.sysConf.sys_account_code" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" fluid alt="Responsive image" blank-color="#f3f3f3"></b-img-lazy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import scroll from 'vue-seamless-scroll'
export default {
  name: 'Home',
  components: {
    scroll
  },
  data() {
    return {
      bannerSlide:0,
      adsenseSlide:0,
      slide: 0,
      sliding: null,
      domain:this.ApiUrl,
      bannerList:[],
      adsense_data:[],
      cateArticleData17:{
        cate:[],
        article:[]
      },
      cateArticleData9:{
        index:0,
        cate:[],
        cate_article:[],
        article:[]
      },
      homeMenuArticleData:{
        index:0,
        cate:[],
        article:[],
        charityArticle:[]
      },
      linkData: {
        linkTree: [],
        index: 0
      },
      menuTree:[],
      updateScrollSataus:false
    }
  },
  computed: {
    classOption () {
      return {
        singleHeight: 0,
        singleWidth:0,
        direction:2,
        limitMoveNum0:5,
        waitTime: 2000,
        isSingleRemUnit:true,
        step: 0.5,
        hoverStop: true,
        openWatch:true,
        fShow:false
      }
    }
  },
  watch:{
    "linkData.index"(){
      this.updateScrollSataus = false;
      this.$nextTick(()=>{
        this.updateScrollSataus = true;
      })
    }
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      this.fShow = true;
      this.requestApi('getHomeData', {}).then((res) => {
        if(res.status==1){
          this.bannerList = res.banner_data;
          this.cateArticleData9 = res.cateArticleData9;
        }
        this.$nextTick(()=>{
          this.getLastData();
          this.fShow = false;
        })
      })
    },
    getLastData(){
      this.requestApi('getLastData', {}).then((res) => {
        if(res.status==1){
          this.adsense_data = res.adsense_data;
          this.cateArticleData17 = res.cateArticleData17;
          this.homeMenuArticleData = res.homeMenuArticleData;
          this.linkData = res.linkList;
          this.menuTree = res.menuTree;
          this.$nextTick(()=>{
            this.updateScrollSataus = true;
          })
        }
      })
    },
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    }
  }
}
</script>
<style lang="scss">
  @import "~/src/assets/css/home";
</style>
