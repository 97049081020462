<template>
  <div>
    <div class="error-m">
      <b-img-lazy :src="require('/src/assets/common/img/404.png')" blank-color="#f3f3f3"></b-img-lazy>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",



}
</script>

<style lang="scss">
  .error-m{
    min-height: 500px;line-height: 500px;text-align: center;
  }
</style>