<template>
  <div class="header-m">
    <div class="nav-menu">
      <b-link to="/" class="logo-img-m">
        <b-img-lazy class="logo-img" width="336" height="74" :src="require('/src/assets/common/img/logo.png')"></b-img-lazy>
      </b-link>
      <div class="ser-nav-m">
        <div class="ser-m">
          <div class="ser-m-item">
            <b-input-group>
              <b-form-input class="ser-input" v-model="keywords" placeholder="新闻标题关键词"></b-form-input>
              <b-input-group-prepend>
                <b-button class="btn-ser" :to="{path:'/search',query:{keywords:keywords}}">搜索</b-button>
              </b-input-group-prepend>
            </b-input-group>
          </div>
        </div>
        <div class="nav-m">
          <div class="nav-m-item">
            <b-link class="nav-item" to="/">首页</b-link>
            <b-link class="nav-item" v-for="(item,index) in menu_tree" :key="index" @click.stop="toUrl(item.link_type,item.id)">
              {{item.name}}
              <div class="nav-child" v-if="item._child">
                <b-link class="nav-child-item" v-for="(val,key) in item._child" :key="key" @click.stop="toUrl(val.link_type,val.id)" >{{ val.name }}</b-link>
              </div>
            </b-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "headerM",
  data(){
    return {
      menu_tree:this.$store.state.menuTree,
      keywords:''
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      this.requestApi('getNav', {}).then((res) => {
        if(res.status==1){
          this.menu_tree = res.menu_tree;
          this.$store.dispatch('editMT',res.menu_tree);
          this.$store.dispatch('editLT',res.cateLinkType);
          this.$store.dispatch('editSU',res.singleUrl);
          this.$store.dispatch('editSC',res.sysConf);
        }
      })
    }
  }
}
</script>

<style lang="scss">
  .header-m{
    width: 100%;min-width: 1200px; height: 115px;background: #ffffff;position: relative;z-index: 99;box-shadow: 0 2px 3px -1px #cccccc;
    .nav-menu{
      width: 1200px;height: 100%; margin:0 auto;display: flex;
      .logo-img-m{
        align-self: center;display: block;width: 336px;height: 74px;overflow: hidden;
        .logo-img{
          align-self: center;
        }
      }
      .ser-nav-m{
        flex: 1;align-self: center;padding-top:10px;
        .ser-m{
          display: flex;height: 30px; justify-content: flex-end;
          .ser-m-item{
            display: inline-flex;border-radius: 15px;overflow: hidden;
            .ser-input{
              display: block; background: #eeeeee; height: 30px;border: 1px solid #eeeeee;
            }
          }
          .btn-ser{
            border-radius: 0;background-color: $ThemeFontColor;border-color: $ThemeFontColor;
          }
        }
        .nav-m{
          display: flex;justify-content: flex-end;
          .nav-m-item{
            align-self: center;display: inline-flex;
            .nav-item{
              display: inline-block;padding:20px 8px 10px;text-decoration: unset;color: #333333;font-size: 16px;position: relative;z-index: 1;
              .nav-child{
                position: absolute;top:55px;width: 100%;min-width: 150px; z-index: 2; background-color: #ffffff;border-top:2px solid $ThemeFontColor;display: none;box-shadow:0 0 4px #cccccc;
                .nav-child-item {
                  display: block;width: 100%;text-decoration: unset;color: #333333;font-size: 16px;height: 40px;line-height: 40px;border-bottom:1px solid #f3f3f3;text-align: center;
                  &:hover{
                    color: $ThemeFontColor;
                  }
                }
              }
              &:hover{
                color: $ThemeFontColor;
                .nav-child{
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
</style>