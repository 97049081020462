import Vue from 'vue'
import axios from 'axios'

Vue.prototype.ApiUrl = 'http://a.chinafea.com/';

Vue.prototype.toUrl = function (link_type,typeId) {
    let singleUrl = this.$store.state.singleUrl;
    let url = this.$store.state.linkType[link_type].url;
    if(singleUrl[typeId] != undefined){
        url = singleUrl[typeId];
        this.$router.push({path:url});
    }else{
        this.$router.push({path:url,query:{typeId:typeId}});
    }
}

Vue.prototype.defaultImg  = function() {
    return 'this.src="' + require('/src/assets/common/img/default.png') +'"';
}

Vue.prototype.requestApi = function (url,data){
    let header = Object.assign({'content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'});
    return new Promise((resolve) => {
        axios({
            method: 'POST',
            url: this.ApiUrl + '/app/' + url,
            data: data ? data : {},
            headers: header,
            responseType: 'json',
        }).then(res => {
            if (res.status == 200) {
                if (res.data.status == 403) {
                    resolve({'status':403,'msg':''});
                    console(res.data.msg);
                    this.$router.push({path:'error'});
                }else {
                    resolve(res.data);
                }
            }
        }).catch(err => {
            this.$router.push({path:'error'});
            console.log(err);
        })
    })
}

