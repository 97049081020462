<template>
  <div id="app">
    <headerM></headerM>
    <div class="content-m">
      <transition :duration="{ enter: 500, leave: 800 }" name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
    <footerM></footerM>
  </div>
</template>
<script>
  import headerM from "@/components/headerM";
  import footerM from "@/components/footerM";
  export default {
    name: 'APP',
    components: {
      headerM, footerM
    },
    data(){
      return{

      }
    },
    watch:{
      $route(){

      }
    }
  }
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.content-m{
  min-height: 300px;
}
</style>
