<template>
  <div class="l-main">
    <div class="cate-m" v-if="cateData">
      <div class="cate-title">{{cateData.name}}</div>
      <b-link v-for="(item,index) in cateData._child" :class="'nav-item child-list'+(cateData.indexId == index?' cur':'')" :key="index" @click.stop="toUrl(item.link_type,item.id)" >{{item.name}}</b-link>
    </div>
    <div class="project-m">
      <div class="title">信息中心</div>
      <div class="pro-list">
        <scroll ref="seamlessScroll" v-if="recProjectData.length > 0" :data="recProjectData" :class-option="classOption" class="table-content">
          <b-link class="item-list" v-for="(item,index) in recProjectData" :to="{path:'/article_details',query:{aId:item.id}}" :key="index">
            <div class="img-m">
              <b-img class="pro-img" :src="domain + item.img" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" blank-color="#f3f3f3"></b-img>
            </div>
            <div class="pro-title">{{item.title}}</div>
          </b-link>
        </scroll>
      </div>
    </div>
    <div class="code-img-m">
      <b-img-lazy class="code-img" :src="domain + $store.state.sysConf.sys_account_code" :onerror="'this.src=\''+require('/src/assets/common/img/default.png')+'\''" fluid alt="Responsive image" blank-color="#f3f3f3"></b-img-lazy>
      <div class="p-cate">欢迎关注福田区企业家协会</div>
    </div>
  </div>
</template>
<script>
import scroll from 'vue-seamless-scroll'
export default {
  name: "leftM",
  components:{
    scroll
  },
  data(){
    return{
      recProjectData:this.$store.state.recProject,
      domain:this.ApiUrl,
      cateData:null
    }
  },
  computed: {
    classOption () {
      return {
        singleHeight: 0,
        singleWidth:0,
        limitMoveNum0:5,
        waitTime: 2000,
        isSingleRemUnit:true,
        step: 0.5,
        hoverStop: true,
        openWatch:true
      }
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      this.requestApi('getRecProject', {}).then((res) => {
        if(res.status==1){
          this.recProjectData = res.data;
          this.$store.dispatch('editRP',res.data);
        }
      })
    },
    initData(e){
      this.cateData = e;
    }
  }
}
</script>

<style lang="scss">
.l-main{
  width: 210px;
  .cate-m{
    .cate-title{
      width: 210px;height: 40px; background: $ThemeFontColor;color: #ffffff;font-size: 16px;text-align: center;line-height: 40px;border-radius: 4px;
    }
    .nav-item{
      display: block; width: 180px;height: 44px;color: #333333;font-size: 14px;text-align: center;line-height: 44px;position: relative;border-bottom:1px solid #f3f3f3;margin: 0 auto;text-decoration: none;
      &:before{
        width: 6px;height: 6px;content: " ";background: $ThemeFontColor;color:$ThemeFontColor;position: absolute;top: 19px;left: 16px;
      }
      &.cur{
        color: $ThemeFontColor;
      }
    }
  }
  .project-m{
    .title{
      width: 210px;height: 40px; background: $ThemeFontColor;color: #ffffff;font-size: 16px;text-align: center;line-height: 40px;border-radius: 4px;margin: 15px 0;
    }
    .pro-list {
      width: 210px;height: 300px;overflow: auto;box-shadow:0 0 4px 4px #f5f5f5;padding:5px;margin-bottom:15px;
      .table-content {
        width: 100%;height: 100%;clear: both;overflow: hidden;
        .item-list {
          display: flex;height: 60px;justify-content: space-between;margin-bottom: 10px;text-decoration: none;
          .img-m {
            width: 88px;height: 60px;overflow: hidden;display: flex;border: 1px solid #dddddd;justify-content: center;
            .pro-img {
              width: 80px;height: auto;align-self: center;
            }
          }
          .pro-title {
            align-self: center;width: 100px;line-height: 20px;color: #3a3a3a;font-size: 12px;text-align: left;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;
          }
        }
      }
    }
  }
  .code-img-m{
    width: 180px;height: auto;border:1px solid #f5f5f5;margin:15px;
    .code-img{
      display: block;width: 160px;height: 160px;margin:10px;
    }
    .p-cate{
      color: #3a3a3a;font-size: 14px;padding-bottom:10px;text-align: center;
    }
  }
}

</style>